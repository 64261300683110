:root {
    --primary: '#1497D4';
}

.text-decoration-none {
    text-decoration: none;
}

.custom-table tr td {
    border: 1px solid rgb(228, 228, 228) !important;
}

.custom-table tr th {
    border: 1px solid rgb(228, 228, 228) !important;
} 

.br-0 {
    border-radius: 0px !important;
}

.custom-input input {
    border-radius: 0px;
}

.ttc {
    text-transform: capitalize !important;
}

.MuiSnackbar-root .MuiAlert-icon {
    margin: auto 10px auto 0px;
}