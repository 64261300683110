.logo_container {
  width: "100%";
  height: 160px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  background-color: white;
  width: 226px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}