.mui-textarea:focus-visible {
  outline: none !important;
  border: 1px solid #1497d4;
}

.mui-textarea {
  outline: none !important;
  border-color: #c7c7c7;
  border-radius: 4px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.mui-textarea:hover {
  outline: none !important;
  border: 1px solid;
}

.mui-textarea:focus-visible:hover {
  outline: none !important;
  border: 1px solid #1497d4;
}

.mui-textarea-error {
  outline: none !important;
  border-color: #d32f2f;
  border-radius: 4px;
  font-family: inherit;
}
