.preview_profile_dialog .MuiPaper-root {
  overflow-y: hidden;
}

.preview_dialog_iframe {
  border: none;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
}

.download_btn:hover {
  background-color: #444 !important;
  color: rgb(19, 168, 255) !important;
}